
@import '@core/styles/_colors.scss';
@import '@core/styles/devicesize.scss';

@keyframes fadeIn {
    0% {
        opacity: 0;
        // transform: scale(0.8);
    }
    100% {
        opacity: 1;
        // transform: scale(1);
    }
}

.wrapper{
    position: relative;


    .animation{
        // opacity: 0; /* Start hidden */
        animation: fadeIn 0.4s ease-in;
    }
    
    .image{
        max-width: 100%;
        @media only screen and (max-width: $widthDesktopMin){
            padding-right: 40px;
        }
        @media only screen and (max-width: $widthMobileMax){
            padding-right: 20px;
        }
    }
    
    .second{
        position: absolute;
        left: 0;
        top: 0;
    }
}


