@import '@core/styles/_colors.scss';
@import '@core/styles/devicesize.scss';

.cart-carousel {
	gap: 24px;
	margin-bottom: 20px;
	.cart-carousl-headline {
		& > h3 {
			font-weight: 900;
			font-size: 24px;
			line-height: 29px;
			color: $colorP1;
			@media only screen and (max-width: $widthMobileMax) {
				font-weight: 700;
				font-size: 18px;
				line-height: 22px;
			}
			@media only screen and (max-width: $widthTabletMax) {
				font-weight: 700;
				font-size: 20px;
				line-height: 24px;
			}
		}
		& > p {
			font-weight: 700;
			font-size: 18px;
			line-height: 22px;
			color: $colorGray70;
			@media only screen and (max-width: $widthMobileMax) {
				font-weight: 400;
				font-size: 14px;
				line-height: 17px;
				color: $colorGray40;
			}
			@media only screen and (max-width: $widthTabletMax) {
				font-weight: 700;
				font-size: 14px;
				line-height: 17px;
			}
		}
	}
	&__header {
		& > header {
			display: flex;
			flex-direction: column;
			gap: 6px;
			& > h2 {
				margin: 0;
				font-weight: 900;
				font-size: 24px;
				line-height: 29px;
				color: $colorGray70;
				@media only screen and (max-width: $widthMobileMax) {
					font-weight: 700;
					font-size: 18px;
					line-height: 22px;
				}
				@media only screen and (max-width: $widthTabletMax) {
					font-weight: 700;
					font-size: 20px;
					line-height: 24px;
				}
			}
		}
		& > div {
			& > div {
				& > button {
					& > svg {
						width: 24px;
						height: 24px;
						stroke: $colorGray30;
					}
					&:disabled {
						& > svg {
							width: 24px;
							height: 24px;
							stroke: $colorGray15;
						}
					}
				}
			}
			& > a {
				min-width: max-content;
			}
		}
	}

	&__contents {
		gap: 24px;
		overflow-x: auto;
		-ms-overflow-style: none; /* IE and Edge */
		scrollbar-width: none; /* Firefox */
		scroll-behavior: smooth;
		&::-webkit-scrollbar {
			display: none;
		}
	}
}
