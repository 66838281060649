@import '@core/styles/colors.scss';

.jq-tab-view-mobile-wrapper {
	& > h3 {
		color: $colorP1;
		font-size: 18px;
		font-weight: 700;
	}
	& > p {
		color: $colorGray70;
		font-size: 14px;
		font-weight: 400;
	}
}
