@import '@core/styles/_colors.scss';

.wrapper {
    border-radius: 12px;
    width: 260px;
    height: 312px;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 12px;
    padding: 8px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    & .content {
        border-radius: 4px;
        background: rgba(255, 255, 255, 0.70);
        backdrop-filter: blur(15px);
        padding: 8px 10px;

        & h3 {
            font-size: 14px;
            font-weight: 700;
            color: $colorGray70;
            min-height: 35px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: normal;
            max-height: 3em;
            margin: 0;
        }

        & .progress-wrapper{
            min-height: 45px;
            display: flex;
            align-items: center;
            width: 100%;
        }

        & span {
            font-size: 10px;
            font-weight: 700;
            color: $colorGray70;
        }

        & a{
            font-size: 14px;
            height: 43px;
        }
    }
}