@import '@core/styles/_colors.scss';
@import '@core/styles/_devicesize.scss';

.latest-job-posts-wrapper {
	background-color: $colorSecondaryLight;
}

.latest-job-posts-header {
	& > div {
		& > h3 {
			color: $colorGray70;
			font-size: 24px;
			font-weight: 900;
			margin: 0;
			@media screen and (max-width: $widthTabletMax) {
				font-size: 18px;
				font-weight: 700;
			}
		}
	}
	& > p {
		margin: 0;
		color: $colorGray70;
		font-size: 18px;
		font-weight: 700;
		@media screen and (max-width: $widthTabletMax) {
			font-size: 14px;
			font-weight: 400;
		}
	}
}

.latest-job-posts-card-wrapper {
	text-decoration: none;
	color: inherit;
	&:hover {
		color: inherit;
	}
	& > div {
		background-color: #fff;
		border: none;
	}
}


.hover-scale{
	margin: 6px;
    transition: all 0.3s ease;
    &:hover {
        transform: scale(1.03);
    }
}