@import '@core/styles/_colors.scss';
@import '@core/styles/_devicesize.scss';

.wrapper {
    text-align: center;
    margin: 116px 0;

    & h3 {
        color: $colorP1;
        font-size: 36px;
        font-weight: 900;
        margin-bottom: 38px;
        @media only screen and (max-width: $widthMobileMax){
            font-size: 36px;
        }
    }

    & h5 {
        color: $colorGray50;
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 38px;
    }

    
}