@import '@core/styles/colors.scss';

.search-page-categories {
	& > h2 {
    color: $colorGray50;
		font-weight: 700;
		font-size: 18px;
		line-height: 22px;
    margin-bottom: 16.5px;
	}
	& > .category-card {
		border: 1px solid $colorGray15;
		padding: 12px;
		border-radius: 8px;
		margin-bottom: 8px;
    background-color: $colorPureWhite;
		width: 100%;
    &.active{
      border: 1px solid $colorP1;
    }
		& > img {
			border-radius: 8px;
			height: 41px;
			width: 41px;
		}
		& > div {
			margin-left: 16px;
      text-align: left;
			& > h3 {
				font-size: 14px;
				line-height: 17px;
				color: $colorGray70;
				margin-bottom: 8px;
			}
			& > h4 {
				margin-bottom: 0;
				font-size: 12px;
				line-height: 15px;
				color: $colorGray30;
				& > span {
					&::after {
						margin: 0 8px;
						content: '\A';
						width: 4px;
						height: 4px;
						border-radius: 50%;
						background: $colorGray30;
						display: flex;
					}
				}
			}
		}
	}
	&> .category-loading{
		width: 100%;
		height: 30vh;
	}
}
