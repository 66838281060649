@import '@core/styles/_colors.scss';
@import '@core/styles/_devicesize.scss';

.wrapper {
    color: $colorGray60;
    display: flex;
    justify-content: center;

    &>a {
        width: fit-content;

        & h3 {
            color: $colorGray70;
            margin-bottom: 5px;
            font-size: 24px;
            text-align: left;
        }

        h5 {
            color: $colorGray40;
            font-size: 14px;
            margin-bottom: 4px;
        }

        span {
            color: $colorGray30;
            margin-top: 10px;
            font-size: 16px;
            font-weight: 700;
            color: #00B67A;
        }

        p {
            margin: 0;
            font-size: 14px;
            color: $colorGray50;
            font-weight: 400;
        }

        img {
            max-height: 27px;
        }

        svg{
            margin-top: 6px;
            width: 131px;
        }


    }

}