@import '@core/styles/_colors.scss';
@import '@core/styles/devicesize.scss';

.join-pharmuni-wrapper {
	& > div {
		& > .join-pharmuni-cart {
			background-color: $colorWarning30;
			padding: 40px;
			margin: 0;
			width: 100%;
			@media screen and (max-width: $widthTabletMax) {
				width: 100%;
				margin-bottom: 24px;
			}
			@media screen and (max-width: $widthMobileMax) {
				padding: 32px 18px;
			}
			border-radius: 16px;
			&  img {
				border-radius: 16px;
				width: 100%;
			}
			& > div {
				flex-grow: 1;
				justify-content: center;
				padding: 0 31px 0 45px;
				@media screen and (max-width: $widthTabletMax) {
					padding: 0 24px 24px 24px;
				}
				@media screen and (max-width: $widthTabletMax) and (min-width: $widthTabletMin) {
					justify-content: center;
				}
				& > h2 {
					font-weight: 900;
					font-size: 24px;
					line-height: 29px;
					color: $colorGray70;
					@media screen and (max-width: $widthMobileMax) {
						font-weight: 700;
						font-size: 18px;
						line-height: 22px;
					}
					@media screen and (max-width: $widthTabletMax) {
						font-weight: 700;
						font-size: 18px;
						line-height: 22px;
					}
				}
				& > ul {
					list-style: none;
					padding: 0;
					& > li {
						font-weight: 400;
						font-size: 16px;
						line-height: 20px;
						display: flex;
						flex-direction: row;
						align-items: baseline;
						gap: 8px;
						& > div > .dot {
							border-radius: 50px;
							width: 8px;
							height: 8px;
						}
					}
				}
				& > a {
					padding: 12px;
					width: 244px;
					@media screen and (max-width: $widthTabletMax) {
						width: 100%;
					}
				}
			}
		}
	}
}

.join-pharmuni-cart__get-started {
	background-color: $colorPLight;
	.dot {
		display: inline-block;
		background-color: $colorP1;
	}
}

.join-pharmuni-cart__partner {
	background-color: $colorSecondaryLight;
	.dot {
		display: inline-block;
		background-color: $colorAccent;
	}
	a {
		background-color: $colorAccent !important;
	}
}
