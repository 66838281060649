@import '@core/styles/_colors.scss';
@import '@core/styles/_devicesize.scss';

.hero-container {
  min-height: 657px;
  background-image: url('../../../../../assets/images/hero-section-background.webp');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: calc(50%) calc(50% + 80px);
  @media only screen and (max-width:$widthMobileMax) {
    min-height: 550px;
  }

  &>h1 {
    font-size: 64px;
    font-style: normal;
    font-weight: 800;
    max-width: 850px;
    text-align: center;
    // animation: glow 2s ease-in-out infinite alternate;
    

    @media only screen and (max-width:$widthMobileMax) {
      font-size: 40px;
    }

    &>span {
      color: $colorP1;
      // margin-left: 10px;
      text-transform: capitalize;
    }
  }

  &>p {
    max-width: 1048px;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    color: $colorGray70;
    margin-top: 42px;

    @media only screen and (max-width:$widthMobileMax) {
      font-size: 14px;
    }
  }

  &>a {
    padding: 14px 48px;
    margin-top: 42px;
  }

}


.pharmuni-name{
  display: inline-flex;
  color: $colorP1;
  margin-left: 10px;
}





.sign {
  margin-left: 10px;
  // position: absolute;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // width: 50%;
  // height: 50%;
  background-image: radial-gradient(ellipse 50% 35% at 50% 50%,
      #6b1839,
      transparent);
  transform: translate(-50%, -50%);
  letter-spacing: 2;
  left: 50%;
  top: 50%;
  // font-family: "Clip";
  // text-transform: uppercase;
  // font-size: 6em;
  color: #ffe6ff;
  text-shadow: 0 0 0.6rem #ffe6ff, 0 0 1.5rem #ff65bd,
    -0.2rem 0.1rem 1rem #ff65bd, 0.2rem 0.1rem 1rem #ff65bd,
    0 -0.5rem 2rem #ff2483, 0 0.5rem 3rem #ff2483;
  animation: shine 2s forwards, flicker 3s infinite;
}

@keyframes blink {

  0%,
  22%,
  36%,
  75% {
    color: #ffe6ff;
    text-shadow: 0 0 0.6rem #ffe6ff, 0 0 1.5rem #ff65bd,
      -0.2rem 0.1rem 1rem #ff65bd, 0.2rem 0.1rem 1rem #ff65bd,
      0 -0.5rem 2rem #ff2483, 0 0.5rem 3rem #ff2483;
  }

  28%,
  33% {
    color: #ff65bd;
    text-shadow: none;
  }

  82%,
  97% {
    color: #ff2483;
    text-shadow: none;
  }
}

.flicker {
  animation: shine 2s forwards, blink 3s 2s infinite;
}

.fast-flicker {
  animation: shine 2s forwards, blink 6s 1s infinite;
}

.meduim-flicker {
  animation: shine 2s forwards, blink 5s 3s infinite;
}

.slow-flicker {
  animation: shine 2s forwards, blink 4s 1s infinite;
}

@keyframes shine {
  0% {
    color: #6b1839;
    text-shadow: none;
  }

  100% {
    color: #ffe6ff;
    text-shadow: 0 0 0.6rem #ffe6ff, 0 0 1.5rem #ff65bd,
      -0.2rem 0.1rem 1rem #ff65bd, 0.2rem 0.1rem 1rem #ff65bd,
      0 -0.5rem 2rem #ff2483, 0 0.5rem 3rem #ff2483;
  }
}

@keyframes flicker {
  from {
    opacity: 1;
  }

  4% {
    opacity: 0.9;
  }

  6% {
    opacity: 0.85;
  }

  8% {
    opacity: 0.95;
  }

  10% {
    opacity: 0.9;
  }

  11% {
    opacity: 0.922;
  }

  12% {
    opacity: 0.9;
  }

  14% {
    opacity: 0.95;
  }

  16% {
    opacity: 0.98;
  }

  17% {
    opacity: 0.9;
  }

  19% {
    opacity: 0.93;
  }

  20% {
    opacity: 0.99;
  }

  24% {
    opacity: 1;
  }

  26% {
    opacity: 0.94;
  }

  28% {
    opacity: 0.98;
  }

  37% {
    opacity: 0.93;
  }

  38% {
    opacity: 0.5;
  }

  39% {
    opacity: 0.96;
  }

  42% {
    opacity: 1;
  }

  44% {
    opacity: 0.97;
  }

  46% {
    opacity: 0.94;
  }

  56% {
    opacity: 0.9;
  }

  58% {
    opacity: 0.9;
  }

  60% {
    opacity: 0.99;
  }

  68% {
    opacity: 1;
  }

  70% {
    opacity: 0.9;
  }

  72% {
    opacity: 0.95;
  }

  93% {
    opacity: 0.93;
  }

  95% {
    opacity: 0.95;
  }

  97% {
    opacity: 0.93;
  }

  to {
    opacity: 1;
  }
}







.anim {
  animation: glitch 1s linear infinite;
  position: relative;
}

@keyframes glitch {

  2%,
  64% {
    transform: translate(2px, 0) skew(0deg);
  }

  4%,
  60% {
    transform: translate(-2px, 0) skew(0deg);
  }

  62% {
    transform: translate(0, 0) skew(5deg);
  }
}

.anim:before,
.anim:after {
  content: attr(title);
  position: absolute;
  left: 0;
}

.anim:before {
  animation: glitchTop 1s linear infinite;
  clip-path: polygon(0 0, 100% 0, 100% 33%, 0 33%);
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 33%, 0 33%);
}

@keyframes glitchTop {

  2%,
  64% {
    transform: translate(2px, -2px);
  }

  4%,
  60% {
    transform: translate(-2px, 2px);
  }

  62% {
    transform: translate(13px, -1px) skew(-13deg);
  }
}

.anim:after {
  animation: glitchBotom 1.5s linear infinite;
  clip-path: polygon(0 67%, 100% 67%, 100% 100%, 0 100%);
  -webkit-clip-path: polygon(0 67%, 100% 67%, 100% 100%, 0 100%);
}

@keyframes glitchBotom {

  2%,
  64% {
    transform: translate(-2px, 0);
  }

  4%,
  60% {
    transform: translate(-2px, 0);
  }

  62% {
    transform: translate(-22px, 5px) skew(21deg);
  }
}







.hero-container {

  a {
    text-decoration: none;
    color: #9ca0b1;
  }





  .wrapper {

    background: linear-gradient(to right, $colorP1 40%, $colorPLight 50%, $colorSecondaryLecture 60%);
    background-size: auto auto;
    background-clip: border-box;
    background-size: 200% auto;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: textclip 5.5s linear infinite;
    display: inline-block;

  }

  @keyframes textclip {
    to {
      background-position: 200% center;
    }
  }

}













@keyframes glow {
  from {
    text-shadow: 0 0 20px #2d9da9;
  }
  to {
    text-shadow: 0 0 30px #34b3c1, 0 0 10px #4dbbc7;
  }
}