@import '@core/styles/_colors.scss';
@import '@core/styles/devicesize.scss';

.wrapper {
    background-color: $colorPLight;
    border-radius: 16px;
    padding: 36px 20px;
    min-height: 500px;
    margin-right: 30px;
    @media screen and (max-width: $widthMobileMax) {
		min-height: 560px;
        margin-right: 20px;
	}

    &>.cart-carousel__header {
        & h2 {
            font-size: 18px;
            font-weight: 700;
            color: $colorP1;
            margin-left: 18px;
        }

        & button {
            padding: 0;
        }

        & a {
            min-width: 80px;
        }
    }


}

.slider-wrapper {
    // position: absolute;
    // max-height: 400px;
    margin-top: -400px;
    margin-left: 20px;
    margin-bottom: 50px;

    @media screen and (max-width: $widthMobileMax) {
		margin-top: -460px;
        margin-bottom: 80px;
	}

    &>div>div{
        padding: 3px;
    }
    &>div>div>div>div {
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.075);
    }
}

.mobile-view-all{
    position: absolute;
    bottom: -40px;
    width: 100px;
    left: calc(50% - 50px);
}