.truncate {
	display: -webkit-box;
	display: -moz-box;
	margin: 0 auto;
	-moz-box-orient: vertical;
	-webkit-box-orient: vertical;
	text-overflow: ellipsis;
	margin-bottom: 12px;
}
.truncate-readmore {
	font-size: 14px !important;
	line-height: 17px !important;
  padding: 0;
  & > svg{
    margin-left: 9px;
  }
}
