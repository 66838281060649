@import '@core/styles/_colors.scss';

.menu-item {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 24px;

	& > svg {
		width: 100%;
	}

	& > p {
		margin: 0;
		font-weight: 400;
		font-size: 14px;
		color: $colorGray50;
	}
}
