@import '@core/styles/_colors.scss';

.certificate-container {
	display: flex;
	gap: 16px;
	align-items: flex-start;
}

.certificate-card {
	flex: 1;
	display: grid;
	grid-template-rows: 80% 20%;
	grid-column-gap: 24px;
	max-width: 268px;
	width: 268px;
	// height: 250px;
	// max-height: 300px;

	& > .image {
		& > img {
			width: 100%;
			height: 100%;
		}
	}

	& > .info {
		padding-top: 20px;
		display: flex;
		align-items: center;
		justify-content: space-between;

		& > .left-side {
			& > .header {
				font-weight: 400;
				font-size: 20px;
				color: $colorGray70;
				display: -webkit-box;
				display: -moz-box;
				-moz-box-orient: vertical;
				-webkit-box-orient: vertical;
				text-overflow: ellipsis;
				overflow: hidden;
				-webkit-line-clamp: 1;
				line-clamp: 1;
				margin: 0;
			}

			& > .sub-header {
				font-weight: 400;
				font-size: 12px;
				color: $colorGray30;
				margin: 10px 0 0 0;
			}
		}
	}
}
