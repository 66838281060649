@import '@core/styles/_colors.scss';
@import '@core/styles/devicesize.scss';

.top-feedback-wrapper {
	& > h2 {
		font-weight: 900;
		font-size: 24px;
		line-height: 29px;
		margin-bottom: 36px;
		& > span {
			color: $colorP1;
		}
		@media only screen and (max-width: $widthMobileMax) {
			font-weight: 700;
			font-size: 18px;
			line-height: 22px;
		}
	}
}
.feedback-comment-wrapper {
	padding: 24px 0;
	@media only screen and (max-width: $widthMobileMax) {
		padding: 0;
	}
	& > .feedback-image {
		& > img {
			width: 100%;
			object-fit: contain;
		}
	}

	
}
.feedback-comment {
	padding: 34px;
	background-color: $colorPLight;
	border-radius: 12px;
	@media only screen and (min-width: $widthTabletMin) {
		min-height: 157px;
	}
	@media only screen and (min-width: $widthDesktopMin) {
		min-height: 300px;
	}
	& > p {
		font-weight: 900;
		font-size: 24px;
		line-height: 31px;
		color: $colorGray60;
		@media only screen and (max-width: $widthMobileMax) {
			font-weight: 900;
			font-size: 14px;
			line-height: 129.4%;
		}
		@media only screen and (max-width: $widthTabletMax) {
			font-weight: 900;
			font-size: 12.5758px;
			line-height: 16px;
		}
	}
	& > div {
		width: 100%;
		color: $colorGray70;
		font-weight: 700;
		font-size: 20px;
		line-height: 24px;

		@media only screen and (max-width: $widthMobileMax) {
			font-weight: 700;
			font-size: 15px;
			line-height: 18px;
		}
		@media only screen and (max-width: $widthTabletMax) {
			font-weight: 700;
			font-size: 11px;
			line-height: 13px;
		}
		& > p {
			margin: 0;
		}
		& > span {
			height: 4px;
			width: 4px;
			border-radius: 50px;
			background-color: $colorGray30;
			margin: 0 12px;
		}
		& > svg {
			margin-right: 3px;
		}
	}
}