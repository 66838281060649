@import '@core/styles/_colors.scss';
@import '@core/styles/_devicesize.scss';

.wrapper {

    background-color: #1D2233;
    


    &>div {
        min-height: 657px;
        background-image: url('../../../../../assets/images/hero-section-background-black.webp');
        background-size: 100%;
        background-repeat: no-repeat;
        // background-position: calc(50%) calc(50% + 80px);
        background-position: center bottom;
        padding: 50px 10px;

        @media only screen and (max-width:$widthMobileMax) {
            min-height: 450px;
        }
    }

    & h3 {
        color: $colorWarning30;
        font-size: 20px;
        font-weight: 700;
        text-align: center;
        margin: 0;
    }

    & h1 {
        font-size: 64px;
        color: $colorPureWhite;
        font-weight: 900;
        text-align: center;
        max-width: 800px;

        &>span {
            color: $colorP1;
        }

        @media only screen and (max-width:$widthMobileMax) {
            font-size: 32px;
        }
    }

    & img{
      max-width: 100%;
    }

    & p {
        color: $colorPureWhite;
        font-size: 20px;
        font-weight: 500;
        max-width: 900px;
        text-align: center;
        max-width: 750px;
        @media only screen and (max-width:$widthMobileMax) {
            font-size: 14px;
        }
    }

    & a{
        padding: 14px 48px;
    }




    .flip-card {
        position: relative;
        display: inline-flex;
        flex-direction: column;
        box-shadow: 0 2px 3px 0 rgba(0, 0, 0, .2);
        border-radius: .2em;
        min-width: 90px;
        text-align: center !important;
        font-size: 3.3rem;
        font-weight: 600;
        border: 1px solid black;
        color: white;
        @media only screen and (max-width:$widthMobileMax) {
            font-size: 1.6rem;
            min-width: 50px;
        }

        & span{
            display: inline-block;
            min-width: 35px;
            @media only screen and (max-width:$widthMobileMax) {
              min-width: 20px;
          }
        }
        // letter-spacing: .2rem;
      }
      
      .top,
      .bottom,
      .flip-card .top-flip,
      .flip-card .bottom-flip {
        height: .75em;
        line-height: 1;
        padding: .25em 0;
        overflow: hidden;
      }
      
      .top,
      .flip-card .top-flip {
        background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(187,58,144,1) 100%);
        border-top-right-radius: .2em;
        border-top-left-radius: .2em;
        border-bottom: 1px solid #42353E;
        text-align: center !important;
      }
      
      .bottom,
      .flip-card .bottom-flip {
        background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(187,58,144,1) 100%);
        display: flex;
        align-items: flex-end;
        justify-content: center;
        border-bottom-right-radius: .2em;
        border-bottom-left-radius: .2em;
       
      }
      
      .flip-card .top-flip {
        position: absolute;
        width: 100%;
        animation: flip-top 250ms ease-in ;
        transform-origin: bottom;
      }
      
      @keyframes flip-top {
        100% {
          transform: rotateX(90deg);
        }
      }
      
      .flip-card .bottom-flip {
        position: absolute;
        bottom: 0;
        width: 100%;
        animation: flip-bottom 250ms ease-out 250ms;
        transform-origin: top;
        transform: rotateX(90deg);
      }
      
      @keyframes flip-bottom {
        100% {
          transform: rotateX(0deg);
        }
      }
           
      .container-segment {
        display: flex;
        flex-direction: column;
        gap: .1em;
        align-items: center;
      }
      
      .segment {
        display: flex;
        gap: .1em;
      }
      
      .segment-title {
        color: $colorWarning30;
        font-size: 14px;
        font-weight: 700;
        @media only screen and (max-width:$widthMobileMax) {
            font-size: 10px;
        }
      }
}