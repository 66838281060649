@import '@core/styles/_colors.scss';
@import '@core/styles/_devicesize.scss';

.skill-tree-container {
    padding: 60px;
    background-color: $colorSecondaryLight;
    @media only screen and (max-width:$widthMobileMax) {
        padding: 60px 24px;
    }

    & h2 {
        font-size: 48px;
        font-style: normal;
        font-weight: 900;
        color: $colorGray70;
        text-align: center;

        @media only screen and (max-width:$widthMobileMax) {
            font-size: 36px;
        }
    }

    & h3 {
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        color: $colorGray50;
        text-align: center;

        @media only screen and (max-width:$widthMobileMax) {
            font-size: 16px;
        }
    }

    & .line-info {
        & .progress-line {
            min-width: 35px;
            border: 1px dashed $colorP1;
            max-height: 1px;
        }

        & .pass-line {
            min-width: 35px;
            border: 1px solid $colorP1;
            max-height: 1px;
        }

        & h6 {
            margin: 0;
            font-size: 12px;
            font-weight: 700;
            color: $colorP1;
        }
    }

    & .chart-title {
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        color: $colorSecondaryLecture;
        @media only screen and (max-width:$widthMobileMax) {
            font-size: 14px;
        }
    }

    & .category-wrapper {
        & .category {
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            background-color: $colorPureWhite;
            border-radius: 15px;
            box-shadow: 0px -4px 24px 0px rgba(0, 0, 0, 0.07);
            width: 156px;
            min-height: 64px;
            transition: all 0.3s ease;

            @media only screen and (max-width:$widthMobileMax) {
                width: 80px;
                min-height: 48px;
            }

            &:hover {
                & h6 {
                    color: $colorSecondaryLecture !important;
                }

                cursor: default;
                transform: scale(1.2);
            }

            &>h6 {
                font-size: 12px;
                font-style: normal;
                font-weight: 600;
                color: $colorGray30;
                margin: 0;
                padding: 0 5px;

                @media only screen and (max-width:$widthMobileMax) {
                    font-size: 10px;
                }

            }

            &.category-2 {
                margin-right: 240px;

                @media only screen and (max-width:$widthMobileMax) {
                    margin-right: 130px;
                }
            }

            &.category-4 {
                margin-right: 350px;

                @media only screen and (max-width:$widthMobileMax) {
                    margin-right: 170px;
                }
            }
        }
    }

    & .skill-tree-image {
        margin-top: -370px;
        margin-bottom: 120px;

        @media only screen and (max-width:$widthMobileMax) {
            max-width: 160px;
            margin-top: -220px;
        }
    }

}