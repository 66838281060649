@import '@core/styles/_colors.scss';
@import '@core/styles/_devicesize.scss';

.university-partenrs-wrapper {
	padding: 100px 0;
	background-color: #fbfaf9;
	& > div {
		max-width: 900px;
	}
	& > div > img {
		width: max-content;
		 max-width: 25%;
		 @media screen and (max-width: $widthMobileMax) {
			max-width: 60%;
		}
	}
	@media screen and (max-width: $widthTabletMax) {
		padding: 40px 0;
	}
}
