@import '@core/styles/_colors.scss';
@import '@core/styles/devicesize.scss';


.section-wrapper {
	background-color: $backgroundDark;
	padding: 60px 0;


	& .slide-wrapper {
		padding: 20px;
		min-height: 300px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		gap: 10px;

		& img{
			max-width: 340px;
		}

		& h3 {
			color: $colorP1;
			font-size: 24px;
			font-weight: 900;
			line-height: 29.26px;
			// margin: 0;

			&.business{
				color: $colorSecondaryLecture;
			}

			&.uni{
				color: $colorWarning30;
			}

			@media screen and (max-width: $widthTabletMax) {
				font-size: 18px;
				font-weight: 700;
				line-height: 21.94px;
			}
		}

		& h4 {
			font-size: 24px;
			font-style: normal;
			font-weight: 900;
			// -webkit-text-stroke-width: 1px;
			-webkit-text-stroke-color: #000;
			color: $colorPureWhite;
			text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
		}

		& p {
			color: $colorPureWhite; 
			font-size: 16px;
			font-weight: 400;
			line-height: 20px;
			margin-bottom: 20px;
			// margin: 0;

			@media screen and (max-width: $widthTabletMax) {
				font-size: 14px;
				font-weight: 400;
				line-height: 17.07px;
			}
		}

		&>div>div{
			padding-top: 40px;
			padding-left: 50px;
		}
	}


	& button {
		max-width: 57px;
		padding: 6px 12px;
		height: 32px;
		border-radius: 8px;
	}
}