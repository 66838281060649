@import '@core/styles/colors.scss';
@import '@core/styles/_devicesize.scss';

.job-board-card-wrapper {
	width: 100%;
	height: 100%;
	border: 1px solid $colorGray20;
	border-radius: 24px;
	padding: 24px;
	& > h5 {
		color: $colorGray70;
		font-size: 16px;
		font-weight: 700;
	}
	& > p {
		color: $colorGray50;
		font-size: 16px;
		font-weight: 400;
	}
	&__active {
		border: 2px solid $colorP1;
	}
}

.job-post-dashboard-card-title {
	& > div {
		& > div {
			width: 32px;
			height: 32px;
			& > img {
				width: 100%;
			}
			& > span {
				display: inline-block;
				font-size: 22px;
				font-weight: 700;
				width: 100%;
				text-align: center;
				background-color: $colorAccent;
			}
		}
		& > h5 {
			color: $colorAccent;
			font-size: 16px;
			font-weight: 400;
			margin: 0;
		}
	}
}

.job-board-card-body {
	& > ul {
		list-style: none;
		padding: 0;
		margin: 0;
		& > li {
			font-size: 14px;
			line-height: 17px;
			text-align: left;
			& > svg {
				margin-right: 8px;
				& > * {
					stroke: $colorP1;
				}
			}
		}
	}
	& > div {
		& > div {
			& > .language {
				margin: 0;
				border-radius: 16px;
				background-color: $colorPLight;
				color: $colorP1;
				font-size: 16px;
				font-weight: 400;
				padding: 4px 8px;
			}
		}
		& > .published {
			margin-bottom: 0;
			color: $colorGray40;
			font-size: 12px;
			font-weight: 400;
			min-width: max-content;
		}
	}

	& .card-footer{
		min-height: 32px;
	}
}

.job-board-card-fav {
	background-color: transparent;
	border: none;
}
.job-board-card-fav-enabled {
	& > svg {
		fill: #faa61a;
	}
}
