@import '@core/styles/_colors.scss';
@import '@core/styles/devicesize.scss';

.job-qualification-card-wrapper {
	background-color: $colorPureWhite;
	border: 1px solid rgba(13, 13, 13, 0.1);
	border-radius: 24px;
	width: 100%;
	padding: 12px;
	& > img {
		max-width: 100%;
		border-radius: 24px;
	}
	& > .job-qualification-card-wrapper__details {
		padding: 12px;
		& > h4 {
			font-weight: 700;
			font-size: 16px;
			line-height: 20px;
			color: $colorGray70;
		}
		& > div {
			padding: 17px;
			& > .job-qualification-card-wrapper__details__categories {
				max-width: 100%;
				& > .categorie {
          & > img {
            width: 30px;
            object-fit: contain;
          }
					& > p {
						font-weight: 400;
						font-size: 12px;
						line-height: 15px;
					}
				}
			}
			& > .job-qualification-card-wrapper__details__desciription {
				& > div {
					& > span {
						height: 8px;
						width: 8px;
						background-color: $colorP1;
						border-radius: 50%;
						display: block;
						margin: 8px 12px;
					}
				}
			}
			& > .job-qualification-card-wrapper__details__rating {
				& > svg {
					width: 15px;
					margin-right: 4px;
				}
				& > span {
					font-weight: 700;
					font-size: 16px;
					line-height: 20px;
					color: $colorGray70;
				}
				& > .dot {
					height: 4px;
					width: 4px;
					background-color: $colorGray30;
					border-radius: 50%;
					margin: 0 4px;
				}
				& > p {
					display: inline-block;
					margin: 0;
					font-weight: 400;
					font-size: 12px;
					line-height: 15px;
					color: $colorGray40;
				}
			}
		}
	}
}
