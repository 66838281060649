
@import '@core/styles/_colors.scss';
@import '@core/styles/_devicesize.scss';

.card {
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.10);
    border-radius: 16px;
    padding: 24px;
    text-align: start;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;


    &>.header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        & span {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            max-width: 250px;
            @media only screen and (max-width: $widthMobileMax){
                max-width: 180px;
            }
        }

        &>div>img {
            width: 40px;
            height: fit-content;
            aspect-ratio: 1;
            border-radius: 50%;
        }

        &>img {
            width: 35px;
            height: 35px;
        }
    }

    &>.date {
        font-size: 12px;
        font-weight: 400;
        color: $colorGray50;
    }

    &>p {
        font-size: 20px;
        font-weight: 400;
        color: $colorGray50;
    }
}