@import '@core/styles/_colors.scss';

.course-container {
	width: 312px;
  height: 100%;
	max-width: 312px;
	display: flex;
  gap: 12px;
	flex-direction: column;
  text-decoration: none;
  color: initial;

	& > img {
		flex: 0.6;
		width: 100%;
		height: 100%;
		border-radius: 12px;
	}

	& > .course-bottom {
		flex: 0.4;

    & > .course-status {
      margin-bottom: 8px;
    }

		& > p {
      width: 100%;
			font-weight: 400;
			font-size: 20px;
			color: $colorGray70;
      display: -webkit-box;
			display: -moz-box;
			-moz-box-orient: vertical;
			-webkit-box-orient: vertical;
			text-overflow: ellipsis;
			overflow: hidden;
			-webkit-line-clamp: 4;
			line-clamp: 4;
      margin: 0;
		}

		& > .course-info {
			display: flex;
			align-items: center;
			gap: 8px;

			& > .dot {
				width: 4px;
				height: 4px;
				background: $colorGray30;
				border-radius: 50%;
			}

			& > .rating {
				display: flex;
				align-items: center;
				gap: 4px;
				font-weight: 700;
				font-size: 14px;
				color: $colorGray70;
			}

			& > .stuff {
				font-weight: 400;
				font-size: 12px;
				color: $colorGray40;
			}
		}
	}
}
