@import '@core/styles/colors.scss';
@import '@core/styles/devicesize.scss';

.lecture-card-top {
	& > img {
		border-radius: 12px;
		width: 124px;
		height: 124px;
		object-fit: cover;
		@media screen and (max-width: $widthMobileMax) {
			width: 70px;
			height: 70px;
		}
	}
	& > div {
		padding-left: 24px;
		& > div:first-child {
			.title {
				width: 100%;
				gap: 10px;
				display: flex;
				justify-content: space-between;
				align-items: flex-start;
				& > svg {
					flex-shrink: 0;
				}
				& > h4 {
					font-size: 20px;
					line-height: 24px;
					color: $colorGray70;
					margin-bottom: 8px;
					display: -webkit-box;
					display: -moz-box;
					-moz-box-orient: vertical;
					-webkit-box-orient: vertical;
					text-overflow: ellipsis;
					overflow: hidden;
					-webkit-line-clamp: 2;
					line-clamp: 2;
				}
			}
			& > h4 {
				font-size: 20px;
				line-height: 24px;
				color: $colorGray70;
				margin-bottom: 8px;
			}
			& > div {
				@media screen and (max-width: $widthMobileMax) {
					padding-bottom: 12px;
				}
				& > p {
					font-size: 14px;
					line-height: 17px;
					color: $colorGray40;
					margin-bottom: 0;
				}
				& > span {
					margin-right: 6px;
					font-weight: 700;
					font-size: 14px;
					line-height: 17px;
					color: $colorGray70;
				}
			}
		}
		& > div:last-child {
			width: 100%;
			& > .lecture-details {
				min-width: max-content;
				font-size: 14px;
				line-height: 17px;
				color: $colorP1;
				text-decoration: none;
				display: flex;
				justify-content: flex-end;
			}
		}
	}
	& > div:last-child {
		& > .lecture-details {
			min-width: max-content;
			font-size: 14px;
			line-height: 17px;
			color: $colorP1;
		}
	}
}

.lecture-card-wrapper {
	text-decoration: none;
	& > div:last-child {
		width: 100%;
		margin-top: 12px;
		& > h3 {
			margin-bottom: 0;
			font-weight: 700;
			font-size: 20px;
			line-height: 24px;
			color: $colorGray70;
		}
		& > .lecture-details {
			min-width: max-content;
			font-size: 14px;
			line-height: 17px;
			color: $colorP1;
		}
	}
}

.dot {
	&::after {
		margin: 0 8px;
		content: '\A';
		width: 4px;
		height: 4px;
		border-radius: 50%;
		background: $colorGray30;
		display: flex;
	}
}

.home-page {
	& > .lecture-card-top {
		& > img {
			border-radius: 12px;
			width: 69px;
			height: 69px;
			object-fit: cover;
			@media screen and (max-width: $widthMobileMax) {
				width: 70px;
				height: 70px;
			}
		}
		& > div {
			padding-left: 12px;
			& > div:first-child {
				.title {
					width: 100%;
					gap: 10px;
					display: flex;
					justify-content: space-between;
					align-items: flex-start;
					& > svg {
						flex-shrink: 0;
					}
					& > h4 {
						font-size: 20px;
						line-height: 24px;
						color: $colorGray70;
						margin-bottom: 8px;
						display: -webkit-box;
						display: -moz-box;
						-moz-box-orient: vertical;
						-webkit-box-orient: vertical;
						text-overflow: ellipsis;
						overflow: hidden;
						-webkit-line-clamp: 2;
						line-clamp: 2;
						// width: 100%;
						// height: 100%;
						// font-size: 20px;
						// line-height: 24px;
						// color: $colorGray70;
						// margin-bottom: 0px;
						// -moz-box-orient: vertical;
						// -webkit-box-orient: vertical;
						// text-overflow: ellipsis;
						// white-space: nowrap;
						// overflow: hidden;
						// -webkit-line-clamp: 1;
						// line-clamp: 1;
					}
				}
				// & > h4 {
				// 	font-size: 20px;
				// 	line-height: 24px;
				// 	color: $colorGray70;
				// 	margin-bottom: 8px;
				// 	display: -webkit-box;
				// 	display: -moz-box;
				// 	-moz-box-orient: vertical;
				// 	-webkit-box-orient: vertical;
				// 	text-overflow: ellipsis;
				// 	overflow: hidden;
				// 	-webkit-line-clamp: 2;
				// 	line-clamp: 2;
				// }
				& > div {
					width: max-content;
					@media screen and (max-width: $widthMobileMax) {
						padding-bottom: 0;
					}
					& > p {
						font-size: 14px;
						line-height: 17px;
						color: $colorGray40;
						margin-bottom: 0;
					}
					& > span {
						margin-right: 6px;
						font-weight: 700;
						font-size: 14px;
						line-height: 17px;
						color: $colorGray70;
					}
				}
			}
			& > div:last-child {
				width: 100%;
				& > .lecture-details {
					min-width: max-content;
					font-size: 14px;
					line-height: 17px;
					color: $colorP1;
				}
			}
		}
		& > div:last-child {
			& > .lecture-details {
				min-width: max-content;
				font-size: 14px;
				line-height: 17px;
				color: $colorP1;
			}
		}
	}
	& > div:last-child {
		width: 100%;
		margin-top: 12px;
		& > .lecture-details {
			min-width: max-content;
			font-size: 14px;
			line-height: 17px;
			color: $colorP1;
			display: flex;
			justify-content: flex-end;
		}
	}
}
