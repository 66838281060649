@import '@core/styles/colors.scss';

.cart-item-price {
	& > h2 {
		position: relative;
		margin: 0;
		color: $colorGray70;
		font-weight: 700;
		font-size: 20px;
		line-height: 24px;
		&.strike {
			color: $colorGray30;
			font-weight: 700;
			font-size: 16px;
			line-height: 20px;
			&::after {
				content: '';
				width: 100%;
				height: 1.5px;
				background: $colorAlert;
				position: absolute;
				top: 10px;
				left: 0;
				// transform: rotate(-12deg);
				transform: matrix(-0.92, 0.14, -0.46, -0.99, 0, 0);
			}
		}
		&.promotion-percent {
			background-color: $colorSuccess;
			color: $colorPureWhite;
			border-radius: 4px;
			padding: 4px 6px;
			font-weight: 700;
			font-size: 14px;
			line-height: 17px;
		}
	}
}
