@import '@core/styles/_colors.scss';
@import '@core/styles/devicesize.scss';

.learn-and-grow-wrapper {
	background-color: $colorSecondaryLight;
}

.learn-and-grow-container {
	& > img {
		object-fit: fill;
	}
	& > div {
		& > div {
			& > h3 {
				color: $colorGray70;
				font-size: 24px;
				font-weight: 900;
				line-height: 29px;
				@media screen and (max-width: $widthTabletMax) {
					font-size: 18px;
					font-weight: 700;
				}
			}
			& > p {
				color: $colorGray70;
				font-size: 14px;
				font-weight: 400;
				line-height: 17px;
			}
		}
		& > ul {
			list-style: none;
			padding: 0;
			& > li {
				& > h4 {
					color: $colorGray70;
					font-size: 18px;
					font-weight: 700;
				}
				& > p {
					color: $colorGray70;
					font-size: 14px;
					font-weight: 400;
					line-height: 17px;
				}
			}
		}
	}
}
