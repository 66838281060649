@import '@core/styles/_colors.scss';
@import '@core/styles/devicesize.scss';

.our-partners-wrapper {
	background-color: $colorSecondaryLight;
	padding: 54px 0;

	@media only screen and (max-width: $widthMobileMax) {
		padding: 34px 0;
	}
	& > div {
		& > h2 {
			font-weight: 900;
			font-size: 24px;
			line-height: 29px;
			@media only screen and (max-width: $widthMobileMax) {
				font-weight: 700;
				font-size: 18px;
				line-height: 22px;
			}
			@media screen and (max-width: $widthTabletMax) {
				font-weight: 700;
				font-size: 18px;
				line-height: 22px;
			}
		}
		& > .our-partners-carousel-wrapper {
			background: rgba(255, 255, 255, 0.88);
			width: 792px;
			max-width: 100%;
			padding: 24px;
			border-radius: 12px;
			padding-bottom: 12px;
		}
		& > .partners-icon {
			width: 792px;
			max-width: 100%;
		}
	}
}

.partner-card {
	height: 100%;
	padding: 24px;
	& > div {
		min-height: 100px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	& > p {
		max-width: 613px;
		text-align: center;
		font-weight: 700;
		font-size: 20px;
		line-height: 24px;
		@media screen and (max-width: $widthTabletMax) {
			font-size: 14px;
			line-height: 22px;
		}
	}
	& > h5 {
		margin: 0;
		color: $colorGray40;
		font-weight: 400;
		font-size: 12px;
		line-height: 15px;
	}
	& > span {
		color: $colorGray60;
		font-weight: 400;
		font-size: 12px;
		line-height: 15px;
	}
}
