@import '@core/styles/colors.scss';

.jq-tab-content-title {
	& > h3 {
		color: $colorGray70;
		font-size: 16px;
		font-weight: 700;
	}
	& > div {
		min-width: max-content;
		& > div {
			min-width: max-content;
		}
		& > a {
			min-width: max-content;
		}
	}
}

.jq-tab-content-wrapper {
	background-color: #fbfaf9;
	padding: 40px 32px 32px 30px;
	border-radius: 8px;
	margin-left: -6px;
}

.jq-tab-wrapper {
	display: flex;
	flex-wrap: wrap;
	max-width: 100%;
	& > div {
		&:first-of-type {
			width: 320px;
		}
		&:last-of-type {
			min-width: 0;
			flex: 1 1 68%;
		}
	}
}

.jq-tab-view-desktop-wrapper {
	& > h3 {
  color: $colorP1;
  font-size: 20px;
  font-weight: 700;
	}
	& > p {
    color: $colorGray70;
    font-size: 18px;
    font-weight: 700;
	}
}
