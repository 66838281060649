@import '@core/styles/_colors.scss';
@import '@core/styles/_devicesize.scss';

.google-reviews-header {
	& > div {
		& > h3 {
			color: $colorGray70;
			font-size: 24px;
			font-weight: 900;
			& > span {
				color: $colorP1;
			}
			@media screen and (max-width: $widthTabletMax) {
				font-size: 18px;
				font-weight: 700;
			}
		}
	}
}

.google-reviews-card-wrapper {
	padding: 24px;
	height: 100%;
	border-radius: 16px;
	background: #fff;
	box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
	& > p {
		color: $colorGray50;
		font-size: 20px;
		font-weight: 400;
	}
	& > div {
		& > img {
			width: 64px;
			height: 64px;
		}
		& > div {
			& > h4 {
				color: $colorGray70;
				font-size: 20px;
				font-weight: 600;
				margin: 0;
        // width: max-content;
			}
			& > span {
				font-size: 12px;
			}
			& > p {
				color: $colorGray70;
				font-size: 12px;
				margin: 0;
        min-width: max-content;
			}
		}
	}
}

.filled-star {
	fill: #ffc961;
	* {
		stroke: #ffc961;
	}
}
.empty-star {
	fill: #D9D9D9;
	* {
		stroke: #D9D9D9;
	}
}
