@import '@core/styles/_colors.scss';
@import '@core/styles/devicesize.scss';

.coming-soon-wrapper {
	gap: 70px;
	@media screen and (max-width: $widthMobileMax) {
		gap: 24px;
	}
	& > h2 {
		font-weight: 900;
		font-size: 24px;
		line-height: 29px;
		@media screen and (max-width: $widthTabletMax) {
			font-weight: 700;
			font-size: 18px;
			line-height: 22px;
		}
		@media screen and (max-width: $widthMobileMax) {
			font-weight: 700;
			font-size: 18px;
			line-height: 22px;
		}
	}
	& > div > .coming-soon-card {
		width: 868px;
		max-width: 100%;

		& > img {
			width: 100%;
			object-fit: fill;
			border-radius: 16px;
		}
		& > div {
			padding: 24px;
			margin-top: -20px;
			width: 90%;
			max-width: 570px;
			background-color: $colorGray10;
			border-radius: 16px;
			& > h5 {
				font-weight: 700;
				font-size: 12px;
				line-height: 15px;
				color: $colorGray40;
				margin-bottom: 9px;
			}
			& > h3 {
				font-weight: 900;
				font-size: 24px;
				line-height: 29px;
				color: $colorAccent;
				margin: 0;
				@media screen and (max-width: $widthMobileMax) {
					font-weight: 700;
					font-size: 14px;
					line-height: 17px;
				}
			}
			& > h2 {
				font-weight: 900;
				font-size: 24px;
				line-height: 29px;
				color: $colorGray40;
				margin-bottom: 16px;
				@media screen and (max-width: $widthMobileMax) {
					font-weight: 700;
					font-size: 14px;
					line-height: 17px;
				}
			}
			& > p {
				font-weight: 400;
				font-size: 14px;
				line-height: 17px;
				color: $colorGray40;
        margin: 0;
			}
		}
	}
}
