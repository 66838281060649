@import '@core/styles/_colors.scss';
@import '@core/styles/devicesize.scss';

.section-wrapper {
	position: relative;
	overflow: hidden;

	&>div>div>h2 {
		font-size: 40px;
		font-weight: 900;
		color: $colorP1;
		margin-bottom: 18px;
	}

	& h3 {
		font-size: 18px;
		font-weight: 700;
		color: $colorGray50;
		margin-bottom: 18px;
	}

	&>div>div>p {
		font-size: 14px;
		font-weight: 400;
		color: $colorGray50;
	}
}

.cart-wrapper {
	flex: 1 0 315px;
	max-width: 380px;
	// padding: 12px;
	// border: 1px solid rgba(13, 13, 13, 0.1);
	border-radius: 24px;
	display: inline-grid;

	&>a {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}
}

.free-lectures-wrapper {
	padding: 34px 0;
	border-radius: 16px;
	// background-color: $colorPLight;

	@media screen and (max-width: $widthTabletMax) {
		padding: 0;
		border: unset;
	}
}

.category-wrapper {
	@media screen and (max-width: $widthMobileMax) {
		overflow-x: scroll;

		&::-webkit-scrollbar {
			width: 4px;
			height: 4px;
		}

		&::-webkit-scrollbar-track {
			background: $colorGray20;
		}

		&::-webkit-scrollbar-thumb {
			background: $colorGray30;
		}

		&::-webkit-scrollbar-thumb:hover {
			background: $colorGray40;
		}
	}


	& .category {
		border: 2px solid black;
		border-radius: 16px;
		padding: 6px 24px;
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
		font-size: 12px;
		font-weight: 400;
		max-width: 130px;
		cursor: pointer;


		&.active {
			border-color: $colorP1;
			color: $colorP1;
		}
	}
}

.hover-scale{
	margin: 6px;
    transition: all 0.3s ease;
    &:hover {
        transform: scale(1.03);
    }
}