@import '@core/styles/colors.scss';

.content-recommendation-card {
	background-color: $colorPureWhite;
	padding: 8px 8px 24px 8px;
	border-radius: 16px;
	height: 100%;
	&__header {
		position: relative;
		& > a > img {
			width: 100%;
			aspect-ratio: 16 / 9;
			object-fit: cover;
			border-radius: 12px;
		}
		&__coming-soon {
			position: absolute;
			left: 3%;
			bottom: 5%;
			background-color: $colorWarning40;
			color: $colorPureWhite;
			font-size: 10px;
			font-weight: 700;
			padding: 4px 8px;
			border-radius: 100px;
			z-index: 5;
		}
		&__normal {
			z-index: 5;
			position: absolute;
			left: 3%;
			bottom: 5%;
			& > span {
				width: max-content;
				background-color: $colorSuccess;
				color: $colorPureWhite;
				font-size: 10px;
				font-weight: 700;
				padding: 4px 8px;
				border-radius: 100px;
			}
			& > div {
				background-color: $colorPureWhite;
				padding: 4px 8px;
				border-radius: 8px;
				& > p {
					margin: 0;
					color: $colorGray40;
					font-size: 12px;
					font-weight: 400;
					line-height: 19px;
					letter-spacing: 0px;
					text-align: left;
				}
				& > h6 {
					margin: 0;
					color: $colorGray70;
					font-size: 16px;
					font-weight: 700;
					line-height: 19px;
				}
			}
		}
	}

	&__desc {
		padding: 0 16px;
		flex-grow: 1;
		& > h5 {
			color: $colorGray70;
			font-size: 16px;
			font-weight: 700;
			line-height: 20px;
			text-align: left;
			display: -webkit-box;
			display: -moz-box;
			-moz-box-orient: vertical;
			-webkit-box-orient: vertical;
			text-overflow: ellipsis;
			overflow: hidden;
			-webkit-line-clamp: 1;
			line-clamp: 1;
			margin: 0;
		}
		& > p {
			color: $colorGray70;
			font-size: 12px;
			font-weight: 400;
			line-height: 14.63px;
			text-align: left;
			display: -webkit-box;
			display: -moz-box;
			-moz-box-orient: vertical;
			-webkit-box-orient: vertical;
			text-overflow: ellipsis;
			overflow: hidden;
			-webkit-line-clamp: 3;
			line-clamp: 3;
			margin: 0;
		}
		& > div {
			flex-grow: 1;
			align-items: flex-end;
			margin-top: 10px;
			& > a {
				font-size: 14px;
				font-weight: 400;
				& > svg {
					margin-left: 4px;
				}
			}
		}
	}
}

.seperator-dot {
	align-self: center;
	&::after {
		margin: 0 8px;
		content: '\A';
		width: 4px;
		height: 4px;
		border-radius: 50%;
		background: $colorGray30;
		display: flex;
	}
}

.vip-access{
	position: absolute;
	right: 5%;
	bottom: 5%;
	z-index: 5;
}