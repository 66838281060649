@import '@core/styles/_colors.scss';
@import '@core/styles/devicesize.scss';

.counter {
    font-size: 56px;
    font-style: normal;
    font-weight: 900;
}

.description{
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 50px;
}

.wrapper{
    background-color: $colorGray10;
    padding-top: 50px;
    border-radius: 16px;
}