@import '@core/styles/colors.scss';
@import '@core/styles/_devicesize.scss';

.carousel-wrapper {
	& > div {
		& > div {
			& > span {
				background-color: $colorP1;
			}
			& > div {
				@media only screen and (max-width: $widthTabletMax) {
					text-align: center;
				}
			}
		}
	}
	a {
		text-decoration: none;
	}
}
@media only screen and (max-width: $widthTabletMax) {}
.carousel-image {
	width: 100%;
	object-fit: fill;
	aspect-ratio: 1440/ 582;
	@media only screen and (max-width: $widthTabletMax) {
		aspect-ratio: 360/ 516;
		max-width: 600px;
	}
}

.carousel {
	& > div {
		padding-left: 24px;
		& > h3 {
			font-weight: 800;
			font-size: 36px;
			line-height: 44px;
			color: $colorP1;
			@media only screen and (max-width: $widthMobileMax) {
				font-weight: 900;
				font-size: 24px;
				line-height: 29px;
			}
			@media only screen and (max-width: $widthTabletMax) {
				font-weight: 800;
				font-size: 21.44px;
				line-height: 26px;
			}
		}
		& > div {
			font-style: normal;
			font-weight: 500;
			font-size: 20px;
			line-height: 24px;
			color: $colorGray70;
			@media only screen and (max-width: $widthMobileMax) {
				font-weight: 400;
				font-size: 14px;
				line-height: 17px;
				color: $colorGray40;
			}
			@media only screen and (max-width: $widthTabletMax) {
				font-weight: 500;
				font-size: 11.9111px;
				line-height: 15px;
			}
		}
	}
}
