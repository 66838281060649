@import '@core/styles/_colors.scss';
@import '@core/styles/devicesize.scss';

.level-up-background {
    background-image: url('../../../../assets/images/level-up-background.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50% calc(50% + 60px);

    @media only screen and (max-width: $widthTabletLargeMax) {
        background-position: 0;
        background-size: cover;
        background-repeat: repeat-y;
    }

    .level-up-wrapper {
        padding: 100px 20px;

        & h2 {
            text-align: center;
            font-size: 48px;
            font-style: normal;
            font-weight: 900;
            color: $colorGray70;
            @media only screen and (max-width:$widthMobileMax) {
                font-size: 36px;
            }
            
        }

        & h3 {
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            color: $colorGray50;
            text-align: center;
            margin-top: 20px;
            @media only screen and (max-width:$widthMobileMax) {
                font-size: 14px;
            }
        }


        & .level-up-card {
            display: flex;
            justify-content: center;
            flex-direction: column;
            background-color: $colorSecondaryLight;
            border-radius: 16px;
            box-shadow: 0px 4px 50px 10px rgba(0, 0, 0, 0.07);
            padding: 34px 54px 0;
            min-height: 340px;
            @media only screen and (max-width:$widthMobileMax) {
                padding: 24px 24px 0;
                min-height: 240px;
            }

            &.second {
                justify-content: flex-start;
                align-items: center;
                padding: 34px 34px 0;

                &>h4 {
                    margin-bottom: 20px;
                    font-size: 20px;
                    font-weight: 700;
                }

                &>img {
                    max-width: 350px;
                    @media only screen and (max-width: $widthTabletLargeMax) {
                        max-width: 250px;                        
                    }
                }
            }

            &.first {
                &.boost>img{
                    max-width: 485px;
                    width: 100%;
                    margin: 25px auto 0;
                }
                &.profile>img{
                    margin-top: 28px;
                }
                @media only screen and (max-width: $widthMobileMax) {
                    justify-content: space-between;
                }
            }

            &.chart {
                justify-content: space-between;
                @media only screen and (max-width: $widthTabletLargeMax) {
                    justify-content: space-between;
                    
                }
            }

            & h4 {
                font-size: 24px;
                font-weight: 900;
                color: $colorSecondaryLecture;
                text-align: center;
                min-height: 52px;

                @media only screen and (max-width: $widthMobileMax) {
                    font-size: 18px;
                    font-weight: 700;
                }
            }

            & h5 {
                margin-bottom: 10px;
                font-size: 14px;
                font-weight: 700;
                color: $colorGray50;
                text-align: center;
                @media only screen and (max-width: $widthMobileMax) {
                    font-size: 12px;
                    font-weight: 700;
                }
            }
        }
    }
}