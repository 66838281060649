@import '@core/styles/colors.scss';
@import '@core/styles/devicesize.scss';

.course-card-wrapper {
	text-decoration: none;
	& > img {
		border-radius: 12px;
		width: 264px;
		min-width: 264px;
		height: 159.5px;
		// aspect-ratio: 100 / 56.25;
		@media screen and (max-width: $widthMobileMax) {
			width: 100%;
			height: unset;
		}
	}
	& > div {
		padding-left: 24px;
		@media screen and (max-width: $widthMobileMax) {
			padding-left: 0;
			padding-top: 24px;
		}
		& > .course-card-right-top {
			& > .title {
				width: 100%;
				gap: 10px;
				display: flex;
				align-items: baseline;
				& > svg {
					flex-shrink: 0;
				}
				& > h4 {
					width: 100%;
					height: 100%;
					font-size: 20px;
					line-height: 24px;
					color: $colorGray70;
					margin-bottom: 0px;
					-moz-box-orient: vertical;
					-webkit-box-orient: vertical;
					text-overflow: ellipsis;
					white-space: nowrap;
					overflow: hidden;
					-webkit-line-clamp: 1;
					line-clamp: 1;
				}
			}

			& > div {
				@media screen and (max-width: $widthMobileMax) {
					padding-bottom: 12px;
				}
				& > p {
					font-size: 12px;
					line-height: 17px;
					color: $colorGray40;
					margin-bottom: 8px;
					align-items: center;
					@media screen and (max-width: $widthMobileMax) {
						margin-bottom: 0;
						margin-right: 20px;
					}
				}
				& > span {
					font-weight: 700;
					font-size: 16px;
					line-height: 20px;
					color: $colorGray70;
					@media screen and (max-width: $widthMobileMax) {
						margin-right: 6px;
						&::after {
							content: '\A';
							width: 4px;
							height: 4px;
							border-radius: 50%;
							background: $colorGray30;
							display: inline-block;
							margin-left: 6px;
						}
					}
				}
			}
		}
		& > div:last-child {
			width: 100%;
			& > .course-details-side {
				min-width: max-content;
				font-size: 14px;
				line-height: 17px;
				color: $colorP1;
				text-decoration: none;
				display: flex;
				justify-content: flex-end;
			}
		}
	}
}

.home-page {
	& > img {
		border-radius: 12px;
		// width: 256px;
		// min-width: 264px;
		// height: 159.5px;
		aspect-ratio: 100 / 56.25;
		@media screen and (max-width: $widthTabletMax) {
			width: 100%;
			// height: unset;
		}
	}
	& > div {
		padding-left: 24px;
		@media screen and (max-width: $widthTabletMax) {
			padding-left: 0;
			padding-top: 24px;
		}
		& > .course-card-right-top {
			& > .title {
				width: 100%;
				gap: 10px;
				display: flex;
				align-items: baseline;
				& > svg {
					flex-shrink: 0;
				}
				& > h4 {
					width: 280px;
					height: 100%;
					font-size: 20px;
					line-height: 24px;
					color: $colorGray70;
					margin-bottom: 0px;
					-moz-box-orient: vertical;
					-webkit-box-orient: vertical;
					text-overflow: ellipsis;
					white-space: nowrap;
					overflow: hidden;
					-webkit-line-clamp: 2;
					line-clamp: 2;
				}
			}
			// & > h4 {
			// 	font-size: 20px;
			// 	line-height: 24px;
			// 	color: $colorGray70;
			// 	margin-bottom: 8px;
			// 	display: -webkit-box;
			// 	display: -moz-box;
			// 	margin: 0 0;
			// 	-moz-box-orient: vertical;
			// 	-webkit-box-orient: vertical;
			// 	text-overflow: ellipsis;
			// 	margin-bottom: 12px;
			// 	overflow: hidden;
			// 	-webkit-line-clamp: 1;
			// 	line-clamp: 1;
			// }
			& > div {
				@media screen and (max-width: $widthTabletMax) {
					padding-bottom: 12px;
				}
				& > p {
					font-size: 12px;
					line-height: 17px;
					color: $colorGray40;
					margin-bottom: 8px;
					align-items: center;
					@media screen and (max-width: $widthTabletMax) {
						margin-bottom: 0;
						margin-right: 20px;
					}
				}
				& > span {
					font-weight: 700;
					font-size: 16px;
					line-height: 20px;
					color: $colorGray70;
					@media screen and (max-width: $widthTabletMax) {
						margin-right: 6px;
						&::after {
							content: '\A';
							width: 4px;
							height: 4px;
							border-radius: 50%;
							background: $colorGray30;
							display: inline-block;
							margin-left: 6px;
						}
					}
				}
			}
		}
		& > div:last-child {
			width: 100%;
			& > .course-details-side {
				min-width: max-content;
				font-size: 14px;
				line-height: 17px;
				color: $colorP1;
				text-decoration: none;
				display: flex;
				justify-content: flex-end;
			}
		}
	}
}

.dot {
	&::after {
		margin: 0 8px;
		content: '\A';
		width: 4px;
		height: 4px;
		border-radius: 50%;
		background: $colorGray30;
		display: flex;
	}
}
