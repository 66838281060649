@import '@core/styles/_colors.scss';
@import '@core/styles/_devicesize.scss';

.section-title {
	font-weight: 800;
	font-size: 24px;
	color: $colorGray70;
  margin-bottom: 24px;
}

.category-section {
	margin: 0 auto;
	display: flex;
	flex-wrap: wrap;
	align-items: baseline;
	// justify-content: space-around;
	border-radius: 12px;
	border: solid 1px rgba(13, 13, 13, 0.1);
	// gap: 14px;
	padding: 16px;

	& > .view-all-btn {
		width: calc(100% / 7);
		// width: 180px;
		font-weight: 700;
		font-size: 14px;
	}
}

.category-item {
	width: calc(100% / 7);
	display: flex;
	flex-direction: column;
	align-items: center;
	// height: 80px;
	// gap: 12px;
	// justify-content: space-between;
	outline: none;
	border: none;
	background: transparent;

	& > img {
		border-radius: 8px;
		width: 56px;
		height: 56px;
		padding: 8px;
		object-fit: contain;
	}

	& > div {
		flex-grow: 1;
		width: 100px;
		font-weight: 400;
		font-size: 12px;
		color: $colorGray70;
		text-align: center;
		margin-top: 12px;
	}
	h1 {
		font-weight: 400;
		font-size: 14px;
	}

	& > div {
		& > h1 {
			color: $colorGray70;
			text-align: left;
		}
		& > div {
			font-weight: 400;
			font-size: 12px;
			color: $colorGray30;
			text-align: left;

			& > div {
				width: 4px;
				height: 4px;
				border-radius: 50%;
				background-color: $colorGray30;
			}
		}
	}
}

.sub-page {
	& > h1 {
		width: 360px;
		color: $colorGray50;
		font-style: normal;
		font-weight: 700;
		font-size: 18px;
		line-height: 22px;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		text-align: center;
	}
}

.modal-content {
	@media only screen and (min-width: $widthTabletMin) {
		min-width: 500px;
	}
	h2 {
		display: none;
	}
}

@media only screen and (max-width: $widthTabletMax) {
	.category-section {
		flex-wrap: wrap;
		padding-bottom: 12px;

		& > .category-item {
			width: calc(100% / 3);
		}

		& > button:nth-child(3n + 1) {
			margin-top: 18px;
		}

		& > button.view-all-btn {
			width: 100%;
		}
	}
}
