@import '@core/styles/_colors.scss';
@import '@core/styles/devicesize.scss';

.wrapper {
    background-color: $backgroundDark;
    background-image: url('../../../../../assets/images/pages/homepage/know-pharmuni-background.png');
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 15px;
    color: $colorPureWhite;
    padding: 50px 0 50px 50px;
    @media only screen and (max-width: $widthMobileMax){
        padding: 30px 0 30px 20px;
    }

    & h3 {
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 30px;
        text-align: center;
        color: $colorWarning40;
        @media only screen and (max-width: $widthMobileMax){
            font-size: 12px;
        }
    }

    & h2 {
        font-size: 24px;
        font-weight: 900;
        color: $colorWarning30;
        text-align: center;
        margin-bottom: 20px;
        @media only screen and (max-width: $widthMobileMax){
            font-size: 18px;
            font-weight: 700;
        }
    }

    & p {
        font-size: 20px;
        font-weight: 400;
        text-align: center;
        padding: 0 20px;
        min-height: 150px;
        @media only screen and (max-width: $widthMobileMax){
            font-size: 14px;
            font-weight: 400;
            padding: 0;
        }
    }

    & .button-list {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        gap: 40px;

        @media only screen and (max-width: $widthDesktopMin){
            flex-direction: row;
            overflow-x: auto;
            margin-right: 30px;
            justify-content: flex-start;
            margin-top: 40px;
        }


        &>button {
            color: $colorPureWhite;
            font-size: 18px;
            font-weight: 700;
            gap: 12px;
            min-width: fit-content;
            text-transform: none;

            &.selected{
                color: $colorSecondaryLecture;
            }
            @media only screen and (max-width: $widthDesktopMin){
                
            }
        }

    }

    & .lear-more-wrapper{
        width: 100%;
        max-width: 750px;
        margin-top: 20px;
        text-align: center;
        @media only screen and (max-width: $widthDesktopMin){
            max-width: none;
        }

        &>div{
            max-width: 150px;
            @media only screen and (max-width: $widthDesktopMin){
                max-width: none;
            }
        }
    }
}