@import '@core/styles/_colors.scss';
@import '@core/styles/_devicesize.scss';

form.subscribe-form {
	max-width: 684px;
	// display: grid;
	// grid-template-columns: 50% 50%;
	// place-items: center;
	display: flex;
	flex-flow: row wrap;
	align-items: center;
	justify-content: space-between;
	gap: 0 16px;
	padding: 24px;
	border-radius: 24px;
	border: 1px solid rgba(13, 13, 13, 0.1);

	@media only screen and (max-width: $widthMobileMax) {
		padding: 0px;
		border: none;
	}

	& > div,
	button {
		flex: 0.5;
	}

	& > button[type='submit'] {
		padding: 10px 0;
		background-color: $colorPLight;
		color: $colorP1;
		font-weight: 700;
		font-size: 14px;
		line-height: 20px;
		border-radius: 8px;
		border: none;
		cursor: pointer;
		&:disabled {
			background-color: $colorGray20;
			box-shadow: none;
			cursor: initial;
		}
		order: 1;
	}

	& > p {
		width: 100%;
		font-weight: 400;
		font-size: 16px;
		order: 2;

		& > a {
			color: rgba(24, 187, 168, 1);
			text-decoration: none;
			margin: 0 5px;
		}
	}
}

@media only screen and (max-width: $widthTabletMax) {
	form.subscribe-form {
		flex-direction: column;
		align-items: stretch;
		gap: 0;
		& > p {
			margin: 24px 0;
			order: 1;
		}
		& > button[type='submit'] {
			order: 2;
			color: $colorPureWhite;
			background-color: $colorP1;
			padding: 12px 0;
			font-weight: 700;
			font-size: 18px;

			& > span {
				display: none;
			}
		}
	}
}
