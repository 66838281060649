@import '@core/styles/colors.scss';

.carousel-wrapper {
  // padding-bottom: 36px;
  & > div:first-child {
    bottom: 0px;
  }
  & > div {
    & > span {
      background-color: $colorP1;
      width: 12px;
      height: 12px;
    }
  }
}