@import '@core/styles/colors.scss';

.content-small-card-wrapper {
  background-color: $colorPureWhite;
  padding: 24px;
  border-radius: 16px;
  height: 100%;
	& > img {
		width: 100%;
		border-radius: 6px;
		aspect-ratio: 256 / 134;
		object-fit: cover;
	}
	& > h3 {
		color: $colorGray70;
		font-size: 14px;
		font-weight: 700;
		line-height: 17px;
	}
}

.content-small-card-footer {
  flex-grow: 1;
  align-items: flex-end;
  padding: 12px 0;
	& > span {
		font-size: 12px;
		font-weight: 700;
    color: $colorGray70;
	}
}
