@import '@core/styles/_colors.scss';
@import '@core/styles/devicesize.scss';

.applications-wrapper {
	padding: 150px 0;
	background-repeat: no-repeat !important;
	background-size: cover !important;
	background-position: center !important;
	width: 100%;
	margin-bottom: -3rem;
	@media screen and (max-width: $widthTabletMax) {
		padding: 91px 0;
	}
	& > article {
		& > div:last-child {
			gap: 32px;
			& > h2 {
				color: $colorGray70;
				font-weight: 900;
				font-size: 24px;
				line-height: 29px;
				text-align: center;
			}
			& > img {
				max-width: 360px;
			}
			& > a {
				width: max-content;
			}
		}
	}
}
