@import '@core/styles/_colors.scss';
@import '@core/styles/_devicesize.scss';

.instagram-wrapper {
	& > h2 {
		color: $colorGray70;
		font-weight: 900;
		font-size: 24px;
		line-height: 29px;
		margin-bottom: 16px;
	}
	& > ul {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		// grid-template-columns: repeat(auto-fill, minmax(148px, 24%));
		justify-content: space-between;
		align-content: space-between;
		gap: 1%;
		list-style: none;
		padding: 0;
		margin: 0;
		// @media screen and (max-width: $widthMobileMax) {
		// 	grid-template-columns: repeat(auto-fill, minmax(148px, 49%));
		// }
		& > li {
			width: calc(100% / 4 - 1%);
			flex-basis: 148px;
			flex-grow: 1;
			// flex : calc(100% /4 - 1%) 0 148px;
			@media screen and (max-width: $widthMobileMax) {
				width: calc(100% / 2 - 1%);
				flex-basis: unset;
				flex-grow: unset;
				margin-top: 1%;
				// max-width: calc(100% / 2 - 1%);;
			}
			// max-width: 264px;
			// min-width: 148px;
			& > a {
				padding: 16px;
				border: 1px solid $colorGray15;
				border-radius: 16px;
				text-decoration: none;
				@media screen and (max-width: $widthTabletMax) {
					padding: 8px;
				}
				& > header {
					& > h6 {
						color: $colorGray40;
						font-weight: 400;
						font-size: 12px;
						line-height: 15px;
					}
				}
				& > img {
					width: 100%;
					border-radius: 8px;
					aspect-ratio: 7 / 8;
					object-fit: cover;
				}
				& > div {
					& > div {
						gap: 20px;
					}
					@media screen and (max-width: $widthTabletMax) {
						& > div {
							gap: 12px;
							& > svg {
								width: 16px;
							}
						}
						& > svg {
							width: 16px;
						}
					}
				}
			}
		}
	}
}
