@import '@core/styles/_devicesize.scss';


.cart-wrapper {
	width: 552px;
	min-width: 552px;
	height: 194px;
	padding: 12px;
	border: 1px solid rgba(13, 13, 13, 0.1);
	border-radius: 24px;
	@media screen and (max-width: $widthTabletMax) {
		width: 270px;
		min-width: 270px;
		height: unset;
		border: none;
		padding: 0;
	}
	& > div {
		width: 100%;
	}
}

.card-wrapper-vertical {
	min-width: 260px;
	height: 312px;
	& > div {
		width: 100%;
	}
}
