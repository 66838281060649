@import '@core/styles/_colors.scss';
@import '@core/styles/devicesize.scss';

.cart-wrapper {
}

.content-big-card {
	gap: 24px;
	padding: 85px 0 60px 0;
	background-repeat: no-repeat;
	background-size: cover;
	background-position-x: center;
	@media only screen and (max-width: $widthMobileMax) {
		padding: 32px 0;
		background-position: bottom;
	}
	.content-big-card-headline {
		& > h3 {
			font-weight: 900;
			font-size: 24px;
			line-height: 29px;
			color: $colorP1;
			@media only screen and (max-width: $widthMobileMax) {
				font-weight: 700;
				font-size: 18px;
				line-height: 22px;
			}
		}
		& > p {
			font-weight: 700;
			font-size: 18px;
			line-height: 22px;
			color: $colorGray70;
			@media only screen and (max-width: $widthMobileMax) {
				font-weight: 400;
				font-size: 14px;
				line-height: 17px;
				color: $colorGray40;
			}
		}
	}
	&__header {
		& > header {
			display: flex;
			flex-direction: column;
			gap: 6px;
			& > h2 {
				margin: 0;
				font-weight: 900;
				font-size: 24px;
				line-height: 29px;
				color: $colorGray70;
				@media only screen and (max-width: $widthMobileMax) {
					font-weight: 700;
					font-size: 18px;
					line-height: 22px;
				}
			}
		}
		& > div {
			& > div {
				& > button {
					& > svg {
						width: 24px;
						height: 24px;
						stroke: $colorGray30;
					}
					&:disabled {
						& > svg {
							width: 24px;
							height: 24px;
							stroke: $colorGray15;
						}
					}
				}
			}
			& > a {
				min-width: max-content;
			}
		}
	}
}
