@import '@core/styles/_colors.scss';
@import '@core/styles/devicesize.scss';

.cart-wrapper {
	flex: 1 0 315px;
	max-width: 380px;
	// padding: 12px;
	// border: 1px solid rgba(13, 13, 13, 0.1);
	border-radius: 24px;
	display: inline-grid;
	& > a {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}
}

.free-lectures-wrapper {
	padding: 34px 70px;
	border-radius: 16px;
	background-color: $colorPLight;
	@media screen and (max-width: $widthTabletMax) {
		padding: 24px;
		border: unset;
	}
}

.hover-scale{
	margin: 6px;
    transition: all 0.3s ease;
    &:hover {
        transform: scale(1.03);
    }
}