@import '@core/styles/_colors.scss';

.awaiting {
    display: flex;
    align-items: center;
    gap: 5px;
    background-color: $colorWarning10;
    color: $colorWarning40;
    width: fit-content;
    padding: 1px 8px;
    border-radius: 12px;
    font-size: 10px;
    font-weight: 700;
}

.completed {
    display: flex;
    align-items: center;
    gap: 5px;
    background-color: $colorSuccess10;
    color: $colorSuccess;
    width: fit-content;
    padding: 1px 8px;
    border-radius: 12px;
    font-size: 10px;
    font-weight: 700;

    &>svg{
        max-width: 16px;
    }
}