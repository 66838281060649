@import '@core/styles/_colors.scss';
@import '@core/styles/_devicesize.scss';

.newsletter-wrapper {
	margin-bottom: -3rem;
	background-color: $colorSecondaryLight;
	padding: 96px 0;
	@media screen and (max-width: $widthMobileMax) {
		padding: 70px 0;
	}
	& > div {
		gap: 48px;
		& > h2 {
			font-weight: 900;
			font-size: 24px;
			line-height: 29px;
			color: $colorGray70;
			@media screen and (max-width: $widthTabletMax) {
				font-weight: 700;
				font-size: 18px;
				line-height: 22px;
			}
			@media screen and (max-width: $widthMobileMax) {
				font-weight: 700;
				font-size: 18px;
				line-height: 22px;
			}
		}
		& > .newsletter-wrapper-cards {
			gap: 4%;
			@media screen and (max-width: $widthTabletMax) {
				gap: 24px;
			}
			& > div {
				width: 32%;
				display: flex;
				flex-direction: column;
				align-items: center;
				text-align: center;
				gap: 12px;
				background-color: $colorPureWhite;
				border-radius: 16px;
				padding: 24px;
				@media screen and (max-width: $widthMobileMax) {
					padding: 40px 0;
					width: 100%;
				}
				@media screen and (max-width: $widthTabletMax) and (min-width: $widthTabletMin) {
					width: 100%;
					align-items: baseline;
				}
				& > div {
					display: flex;
					flex-direction: column;
					gap: 12px;
					align-items: center;
					@media screen and (max-width: $widthTabletMax) and (min-width: $widthTabletMin) {
						flex-direction: row;
					}
					& > h4 {
						margin: 0;
						font-weight: 700;
						font-size: 16px;
						line-height: 20px;
						color: $colorGray70;
						@media screen and (max-width: $widthMobileMax) {
							font-weight: 700;
							font-size: 14px;
							line-height: 17px;
						}
					}
				}
				& > p {
					margin: 0;
					font-weight: 400;
					font-size: 16px;
					line-height: 20px;
					@media screen and (max-width: $widthMobileMax) {
						font-weight: 400;
						font-size: 14px;
						line-height: 17px;
					}
					@media screen and (max-width: $widthTabletMax) and (min-width: $widthTabletMin) {
						text-align: left;
					}
				}
			}
		}
		& > button {
			max-width: 232px;
			font-weight: 700;
			font-size: 14px;
			line-height: 17px;
			align-self: center;
		}
		& > a {
			max-width: 232px;
			font-weight: 700;
			font-size: 14px;
			line-height: 17px;
			align-self: center;
			padding: 18px 26px;
			border-radius: 12px;
		}
	}
}
